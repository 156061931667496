<template>
	<div class="hx-order-one">
		<!-- 患者信息 -->
		<div align="left" class="patientLeft">
				<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
				<div>
					<h3 data-i18n="[html]forms.order.patient_information">患者信息</h3>
					<p data-i18n="forms.order.patient_information_content">请在此处输入患者信息</p>
				</div>
		</div>
		<div class="patientRIght">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-input v-model="ruleForm.doctorId" v-show="false"></el-input>
				<el-input v-model="ruleForm.productId" v-show="false"></el-input>
				<el-form-item label="患者姓名" prop="patientNum" style="width: 560px;">
					<el-input style="width: 387px;float: left;" v-model="ruleForm.patientNum" placeholder="请输入患者姓名或缩写"></el-input>
				</el-form-item>
				<el-form-item prop="year" label="出生日期" style="width: 560px;">
					<!-- <el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="选择日期" v-model="ruleForm.patientBirthday" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd">
					</el-date-picker> -->
					 <el-select v-model="ruleForm.year" placeholder="请选择年" style="width: 120px;float: left;">
					    <el-option
					      v-for="item in yearArr"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>

					<span style="float: left;">&nbsp;-&nbsp;</span>
					<el-select v-model="ruleForm.month" placeholder="请选择月" style="width: 120px;float: left;">
					   <el-option
					     v-for="item in monthArr"
					     :key="item.value"
					     :label="item.label"
					     :value="item.value">
					   </el-option>
					 </el-select>
					
					<span style="float: left;">&nbsp;-&nbsp;</span>
					<el-select  v-model="ruleForm.day" placeholder="请选择日" style="width: 120px;float: left;">
					   <el-option
					     v-for="item in dayArr"
					     :key="item.value"
					     :label="item.label"
					     :value="item.value">
					   </el-option>
					 </el-select>
					
					
				</el-form-item>
				<el-form-item label="性别" prop="patientGender">
					<!-- <el-radio-group v-model="ruleForm.patientGender">
						<el-radio class="sex" label="男"></el-radio>
						<el-radio class="sex" label="女"></el-radio>
					</el-radio-group> -->
					 <el-select style="float: left;" v-model="ruleForm.patientGender" placeholder="请选择">
					    <el-option
					      v-for="item in sexs"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
				</el-form-item>
		<el-form-item style="float: left;position:relative;top: -19px;">
			<span style="color: red;position: relative;top: 2px;">*</span>&nbsp;为必填字段
		</el-form-item  style="padding-bottom:0px !important">
				<el-form-item class="aaa" style="text-align: center;clear: both;position:relative;top: -25px;">
					<el-button class="nextStep" type="primary" @click="submitForm('ruleForm')">下一步</el-button>
					<el-button class="chongzhi" @click="resetForm('ruleForm')">重&nbsp;&nbsp;&nbsp;置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default{
		data:function(){
			 var validatePass2 = (rule, value, callback) => {
			        if (value === '') {
			          callback(new Error('请输入出生日期'));
			        } else if (this.ruleForm.month==='') {
			          callback(new Error('请输入出生日期'));
			        } else if (this.ruleForm.day==='') {
			          callback(new Error('请输入出生日期'));
			        }
					else {
			          callback();
			        }
			      };
			return{
				yearArr:[],
				monthArr:[],
				dayArr:[],
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				sexs:[
					{
						value: '男',
						label: '男'
					},
					{
						value: '女',
						label: '女'
					}
					
				],
				ruleForm: {
					year:'',
					month:'',
					day:'',
					doctorId: '',
					productId: 1,
					patientNum: '',
					patientGender: '',
					patientBirthday: '',
					oid:"0"
				},
				rules: {
					patientNum: [{
							required: true,
							message: '请输入患者编号',
							trigger: 'blur'
						}
					],
					patientGender: [{
						required: true,
						message: '请选择性别',
						trigger: 'change'
					}],
					
					year: [ { validator: validatePass2, trigger: 'blur' }],
					month: [{
						// type: 'date',
						required: true,
						message: '请选择月',
						trigger: 'blur',
						
					}],
					day: [{
						// type: 'date',
						required: true,
						message: '请选择日',
						trigger: 'change',
						
					}]
				},
			
			}
		},
		methods:{
			
			submitForm(formName) {
				var vm=this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.ruleForm.doctorId==""||this.ruleForm.doctorId==0||this.ruleForm.doctorId==null){
							this.ruleForm.doctorId=this.$store.state.doctorId;
						}
						if(this.ruleForm.year!==''&&this.ruleForm.month!==''&&this.ruleForm.day!==''){
							this.ruleForm.patientBirthday=this.ruleForm.year+"-"+this.ruleForm.month+"-"+this.ruleForm.day;
						}
						console.log(this.ruleForm.patientBirthday);
						this.$axios.post("client/order/addOrder",JSON.stringify(this.ruleForm),{
							headers:{
								"Content-type":"application/json"
							}
						}).then(res=>{
							if(res.data.code==200){
								this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderone");
								sessionStorage.setItem("oid",res.data.data);
								this.$store.commit("setOid",res.data.data);
								this.$store.commit("setHxStepIndex",2);
								this.$router.push("/hxmain/hxorder/hxordertwo");
							}else{
								this.$alert("新建订单失败");
							}
							
						}).catch(err=>{
							this.$alert("请联系管理员", "提示");
						})
						
					} else {
						this.$alert("信息填写不完整", "提示");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		mounted:function(){
			let y="";
			for(let i=new Date().getFullYear();i>=1922;i--){
				this.yearArr.push({"value":i,"label":i})
			}
			
			let m="";
			for(let i=1;i<=12;i++){
				if(i<10){
					i="0"+i;
				}
				this.monthArr.push({"value":i,"label":i})
			}	
			let d="";
			for(let i=1;i<=31;i++){
				if(i<10){
					i="0"+i;
				}
				this.dayArr.push({"value":i,"label":i})
			}	
			
			
		
			//document.getElementsByClassName("showSaveOrder")[0].style.display="none";
			this.$store.commit("setHxStepIndex",1);
			
			this.ruleForm.doctorId=sessionStorage.getItem("doctorId");
			this.$store.commit("setBackLocation","/hxmain/hxlatestorder");
			//设置默认地址
			sessionStorage.setItem("location","/hxmain/hxorder/hxorderone")
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
		},
		created:function(){
			
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2 ")
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3 visible"); */
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			if(oid!="undefined"&&oid!=''&&oid!=null&&oid!=undefined){
				this.ruleForm.oid=oid;
				//根据oid查询订单信息
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					let orderInfo=res.data.data;
					if(orderInfo.patientNum!=null){
						this.ruleForm.patientNum=orderInfo.patientNum
					}
					if(orderInfo.patientGender!=null){
						this.ruleForm.patientGender=orderInfo.patientGender
					}
					if(orderInfo.patientBirthday!=null){
						this.ruleForm.patientBirthday=orderInfo.patientBirthday
						let arr=this.ruleForm.patientBirthday.split("-");
						this.ruleForm.year=arr[0];
						this.ruleForm.month=arr[1];
						this.ruleForm.day=arr[2];
					}
					
				}).catch(err=>{
					this.$alert("系统出现异常,请联系管理员!");
				});
			}
		}
	}
</script>

<style scoped="scoped">
	.hx-order-one{
		width: 80%;
		margin: 0px auto;
		display: flex;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		justify-content: space-around;
		/* background-color: green; */
	}
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.patientLeft{
		display: flex;
		width: 30rem;
		justify-content: flex-start;
		/* background-color: red; */
		height: 200px;
	}
	.hx-order-one{
		display: flex;
		margin-top: 3.125rem;
		position: relative;
		justify-content: space-around;
				
	}
	/* 右边 */
	.patientRIght{
		width: 560px;
		/* background-color: orange; */
		
	}
	.patientRIght .aaa{
		margin-bottom: 0px !important;
		
	}
	.patientRIght .el-form{
		height: 280px;
	}
	.nextStep{
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.chongzhi{
		min-width: 7.5rem;
	}
	#yearSelect,#monthSelect,#daySelect{
		float: left;
		  /*  -webkit-appearance: none; */
		  appearance:none;
		  
		  -moz-appearance:none;
		  
		  -webkit-appearance:none;
		 
		  padding-right:14px;
		    background-color: #FFF;
			background-image: none;
		    border-radius: 4px;
		    border: 1px solid #DCDFE6;
		    box-sizing: border-box;
		    color: #606266;
		    display: inline-block;
		    font-size: inherit;
		    height: 40px;
		    line-height: 40px;
		    outline: 0;
		    padding: 0 15px;
			padding-right: 20px;
		    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
		    width: 120px;
	}
	
	
</style>
